import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/axios'
import './filters'
import './components'
import locale from 'element-ui/lib/locale/lang/en'
import components from './plugins'

Vue.use(ElementUI, { locale })
Vue.use(components)

const vueInstance = new Vue({
  router,
  store,
  render: (h) => h(App),
})

vueInstance.$mount('#app')

export default vueInstance
