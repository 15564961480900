<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page.sync="pageComputed"
    :page-size.sync="pageSizeComputed"
    :page-sizes="pageSizes"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    class="mt16"
  >
  </el-pagination>
</template>
<script>
export default {
  name: 'bs-pagination',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 50, 100]
      },
    },
  },
  computed: {
    pageSizeComputed: {
      get() {
        return this.pageSize
      },
      set(val) {
        this.$emit('update:pageSize', val)
      },
    },
    pageComputed: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      },
    },
  },
  methods: {
    handleSizeChange() {
      this.$emit('change', 'sizeChange')
    },
    handleCurrentChange() {
      this.$emit('change', 'pageChange')
    },
  },
  data() {
    return {}
  },
}
</script>
