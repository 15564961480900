<template>
  <div class="wrapper">
    <main class="tac w300">
      <h1 class="mb24">Spider</h1>
      <el-form
        label-width="0px"
        :model="form"
        label-position="left"
        size="medium"
      >
        <el-form-item>
          <el-input
            v-model="form.username"
            @keyup.enter.native="submit"
            prefix-icon="el-icon-user"
            placeholder="username"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="form.password"
            @keyup.enter.native="submit"
            type="password"
            prefix-icon="el-icon-unlock"
            placeholder="password"
            show-password
          />
        </el-form-item>
        <el-form-item>
          <div class="df">
            <div class="flex1">
              <div class="pr8">
                <el-button @click="submit" class="w100p" type="primary">
                  Login
                </el-button>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </main>
  </div>
</template>

<script>
import cookies from 'vue-cookies'
import { loginPost } from './login.api.js'

export default {
  name: 'login',
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
    }
  },
  methods: {
    async submit() {
      if (!this.form.username.trim()) {
        this.$message.error('username is required')
        return
      }
      if (!this.form.password.trim()) {
        this.$message.error('password is required')
        return
      }
      try {
        const { data } = await loginPost(this.form)
        this.loginSuccessHandler(data)
      } catch (err) {
        console.error(err)
        this.$message.error('login failed')
      }
    },
    async loginSuccessHandler(user) {
      const { token } = user
      cookies.set('token', token)
      cookies.set('username', this.form.username)
      this.$store.commit('setUser', user)
      localStorage.setItem('user', JSON.stringify(user))
      this.$router.push({ name: 'databoard' })
    },
  },
}
</script>

<style lang="less" scoped>
.wrapper {
  height: 100vh;
  position: relative;
}
main {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -70%);
}
h1 {
  color: #409eff;
  font-size: 24px;
  font-weight: 700;
}
.ba-link,
.ba-link:visited {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: #999;
  text-decoration: none;
  font-size: 12px;
}
</style>
