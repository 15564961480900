import Vue from 'vue'
import PageHeader from './PageHeader.vue'
import Pagination from './Pagination.vue'
import SearchPanel from './SearchPanel.vue'
import SearchSelect from './SearchSelect.vue'
import SearchInput from './SearchInput.vue'
import Datepicker from './Datepicker.vue'

Vue.component('spider-page-header', Vue.extend(PageHeader))
Vue.component('spider-pagination', Vue.extend(Pagination))
Vue.component('spider-search-input', Vue.extend(SearchInput))
Vue.component('spider-search-select', Vue.extend(SearchSelect))
Vue.component('spider-search-panel', Vue.extend(SearchPanel))
Vue.component('spider-date-picker', Vue.extend(Datepicker))
