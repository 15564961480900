<template>
  <div class="dib">
    <el-button size="small" type="primary" @click="$emit('search')">
      search
    </el-button>
    <el-button size="small" @click="$emit('reset')"> reset </el-button>
    <div class="dib ml16" v-if="expand">
      <el-button type="text" @click="triggerExpand">
        {{ isExpand ? 'hide' : 'expand' }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'search-panel',
  props: {
    expand: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isExpand: false,
    }
  },
  methods: {
    triggerExpand() {
      this.isExpand = !this.isExpand
      this.$emit('tirggerExpand', this.isExpand)
    },
  },
}
</script>
