<template>
  <el-date-picker
    v-bind="$attrs"
    v-on="$listeners"
    type="datetimerange"
    value-format="yyyy-MM-dd HH:mm:ss"
    align="right"
    unlink-panels
    range-separator="to"
    :picker-options="pickerOptions"
    start-placeholder="start time"
    end-placeholder="end time"
  >
  </el-date-picker>
</template>

<script>
export default {
  name: 'bs-date-picker',
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: 'last 7 days',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'last 30 days',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'last 90 days',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
}
</script>
