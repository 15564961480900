<template>
  <div id="app">
    <el-menu mode="horizontal" :router="true" :default-active="defaultActive">
      <el-menu-item index="/databoard">Dashboard</el-menu-item>
      <el-menu-item index="/query">Query</el-menu-item>
      <el-menu-item v-if="!isAdmin" index="/products">Products</el-menu-item>
      <el-submenu v-if="isAdmin" index="/products">
        <template slot="title">Products</template>
        <el-menu-item index="/products">Products</el-menu-item>
        <el-menu-item index="/taskQueue">Batch Delete Queue</el-menu-item>
      </el-submenu>

      <el-menu-item index="/bills">Bills</el-menu-item>

      <el-menu-item v-if="!isAdmin" index="/orders">Orders</el-menu-item>
      <el-submenu v-if="isAdmin" index="/orders">
        <template slot="title">Orders</template>
        <el-menu-item index="/orders">New Orders</el-menu-item>
        <el-menu-item index="/pack">Pack</el-menu-item>
        <el-menu-item index="/fulfilled">Fulfilled</el-menu-item>
        <el-menu-item index="/completed">Completed</el-menu-item>
      </el-submenu>

      <el-submenu v-if="isAdmin" index="/admin">
        <template slot="title">Admin</template>
        <el-menu-item index="/admin/users">Users</el-menu-item>
        <el-menu-item index="/admin/withdraw">Withdraw</el-menu-item>
        <el-menu-item index="/admin/settings">Settings</el-menu-item>
      </el-submenu>
    </el-menu>
    <div class="pa sub-color fs12" style="top: 20px; right: 16px">
      <span class="mr16">v0.81.5</span>
      <el-dropdown trigger="click" @command="dropdownCommandHandler">
        <div class="cp">
          <span>{{ username }}</span>
          <i class="el-icon-arrow-down ml8"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="profile">Profile</el-dropdown-item>
          <el-dropdown-item command="logout">Logout</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="pt32 pl16 pr16 pb16">
      <router-view />
    </div>
  </div>
</template>

<script>
import cookies from 'vue-cookies'

export default {
  name: 'default-layout',
  data() {
    return {
      defaultActive: '/' + this.$route.name,
      username: '',
    }
  },
  computed: {
    isAdmin() {
      const user = JSON.parse(localStorage.getItem('user'))
      return user.isAdmin
    },
  },
  mounted() {
    const username = cookies.get('username')
    this.username = username
  },
  methods: {
    dropdownCommandHandler(command) {
      if (command === 'logout') {
        this.logoutHandler()
      } else if (command === 'profile') {
        this.profileHandler()
      }
    },
    async logoutHandler() {
      cookies.remove('username')
      cookies.remove('token')
      this.$router.push({ name: 'login' })
    },
    async profileHandler() {
      this.$router.push({ name: 'profile' })
    },
  },
}
</script>
