<template>
  <div class="page-header-container">
    <div>
      <section id="title" class="mb16">
        <h1 class="fw700 fs28 dib mr10">{{ title }}</h1>
      </section>
      <ul v-if="summary" class="page-header-summary">
        <li v-for="item in summary" :key="item.label">
          <h4>{{ item.label }}</h4>
          <span>{{ item.value }}</span>
        </li>
      </ul>
    </div>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-header',
  props: {
    title: {
      type: String,
      required: true,
    },
    summary: {
      type: Array,
      required: false,
    },
  },
}
</script>

<style lang="less">
.page-header-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.page-header-summary {
  position: absolute;
  top: 12px;
  right: 16px;
  li {
    display: inline-block;
    color: #606266;
    margin-left: 24px;
    h4 {
      margin-bottom: 2px;
    }
    span {
      color: #909399;
      font-size: 14px;
    }
  }
}
</style>
