import Vue from 'vue'
import axios from 'axios'
import App from '../main.js'
import cookies from 'vue-cookies'
import router from '../router'
const Fetch = {}

/**
 *
 * @comment 对列表类的 GET 请求，通过该函数可以将过滤条件添加至 URL，
 *          使得用户在刷新浏览器页面的时候可以保存刷新前的过滤条件
 * @param {Object} config
 */
function updateURL(config) {
  if (!(config.method && config.method.toLowerCase() === 'get')) {
    return
  }
  const params = config.params
  if (params && config.updateURL) {
    let str = ''
    for (let key in params) {
      if (params[key] !== '') {
        str += `${key}=${params[key]}&`
      }
    }
    if (router.history.current.fullPath !== `${router.history.current.path}?${str.substring(0, str.length - 1)}`) {
      router.replace(`${router.history.current.path}?${str}`)
    }
  }
}

/**
 * 防抖: 防止事件频繁触发
 */
const debounce = (function () {
  const URLCache = {}
  const defaultTimeGap = 2 * 1000
  return function (config) {
    const URL = config.url
    const method = config.method.toUpperCase()
    const limitMethods = ['POST', 'PATCH', 'PUT', 'DELETE']
    const timeGap = config.debounce === 0 ? 0 : config.debounce || defaultTimeGap
    if (limitMethods.find((_) => _ === method)) {
      const previousTime = URLCache[URL]
      const currentTime = Date.now()
      URLCache[URL] = currentTime
      if (currentTime - previousTime < timeGap) {
        App.$message.error('operate too frequent')
        throw Error('debounce error: too frequent')
      }
    }
  }
})()

const removeBlankParams = function (config) {
  const params = config.params
  if (params) {
    for (const prop in params) {
      if (params[prop] === '') {
        delete params[prop]
      }
    }
  }
}

/**
 *
 * @comment 处理 egg-validate 返利的错误信息
 * @param {Object} errors
 */
function dealErrorDetail(errors) {
  errors = errors.errors
  if (!errors || errors.length === 0) {
    console.error('errors.errors error')
    return
  }
  let msg
  try {
    msg = errors[0].field + ': ' + errors[0].message
  } catch {
    msg = 'error...'
  }
  App.$message.error(msg)
}

// 执行 Vue.use 时会执行 Fetch.install
Fetch.install = function (Vue, options) {
  const instance = axios.create({ timeout: 5 * 60 * 1000 })

  /**
   * request拦截器，每次发起网络请求时会执行
   */
  instance.interceptors.request.use(
    (config) => {
      updateURL(config)
      debounce(config)
      removeBlankParams(config)

      config.baseURL = '/'

      // 添加鉴权header
      const token = cookies.get('token')
      if (token) {
        config.headers.common['Authorization'] = `Bearer ${token}`
      }

      // egg csrf token
      config.headers.common['x-csrf-token'] = cookies.get('csrfToken')

      // 添加国际化header
      const locale = cookies.get('local_language')
      if (locale === 'en') {
        config.headers.common['Accept-Language'] = 'en-us'
      } else {
        config.headers.common['Accept-Language'] = 'zh-hant'
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  /**
   * response拦截器，每次起网络响应返回时会执行
   */
  instance.interceptors.response.use(
    (response) => {
      // config.autoShowMessage 为 true 时，自动处理简单的响应数据
      if (response.config.autoShowMessage || response.config.autoShowSuccessMessage) {
        App.$message.success(response.data.msg || 'done')
      }
      return response
    },
    (error) => {
      if (error.response) {
        const { status, data, config } = error.response
        if (status === 401 && App.$route.name !== 'login') {
          // token失效或未登录
          App.$message.error('login required')
          App.$router.push({
            name: 'login',
            params: { redirect: App.$route.fullPath }, // 标记当前页面，登录后重定向回去
          })
        }
        if (status === 403 && (config.autoShowMessage || config.autoShowErrorMessage)) {
          // 无权限
          App.$message({
            message: data.msg || 'can not access',
            type: 'error',
          })
        }
        if (status === 500 && !data.driver) {
          const msg = data._message ? 'system error: ' + data._message : 'system error'
          App.$message.error(msg)
        }
        if (status === 400 && (config.autoShowMessage || config.autoShowErrorMessage)) {
          App.$message.error(data.msg || 'operation error')
        }
        if (status === 422 && (config.autoShowMessage || config.autoShowErrorMessage)) {
          dealErrorDetail(data)
        }
      }
      return Promise.reject(error)
    }
  )
  Vue.prototype.$fetch = function (opt) {
    return instance(opt)
  }
}

Vue.use(Fetch)

export default Vue.prototype.$fetch
