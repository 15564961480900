const sizes = [1, 1.5, 2, 3, 2.5, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5]

export default {
  stockxRates: [0.91, 0.9, 0.89, 0.88],
  sizes,
  sizeOptions: genSizeOptions(sizes),
  productStatusRange: ['pending', 'waiting', 'created', 'synced', 'sold'],
  // orderStatusRange: [
  //   'sold',
  //   'goat_review',
  //   'goat_review_risk_rejected',
  //   'goat_review_skipped',
  //   'fraudulent',
  //   'canceled_by_buyer',
  //   'canceled_by_seller_review',
  //   'canceled_by_seller',
  //   'seller_confirmed',
  //   'seller_packaging',
  //   'with_courier',
  //   'shipped_goat',
  //   'delivered_goat',
  //   'goat_received',
  //   'goat_verified',
  //   'goat_issue_return_to_seller',
  //   'goat_issue_resolved',
  //   'shipped_buyer'
  // ]
  orderStatusRange: [
    { key: 'seller_packaging', label: 'ship' },
    { key: 'with_courier', label: 'fulfilled' },
  ],
}

function genSizeOptions(sizes) {
  return sizes.map((size) => {
    return {
      label: size,
      value: size,
    }
  })
}
