<template>
  <div class="dib vam pl10">
    <el-popover placement="top" trigger="click">
      <el-table size="small" :data="data">
        <el-table-column
          width="150px"
          property="feeType"
          label="item"
        ></el-table-column>
        <el-table-column property="feePrice" label="price"></el-table-column>
      </el-table>
      <el-button slot="reference" type="text" class="detail-button">
        details
      </el-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'revenue-details',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
}
</script>