import pagination from '../components/Pagination.vue'
import VStack from '../components/VStack.vue'
import HStack from '../components/HStack.vue'

const temp = [pagination, VStack, HStack]

export default {
  install(Vue) {
    temp.forEach((i) => {
      Vue.component(i.name, i)
    })
  },
}
