import Vue from 'vue'
import cookies from 'vue-cookies'
import Router from 'vue-router'

import Query from './views/query/Query.vue'
import Login from './views/login/Login.vue'
import DefaultLayout from './layouts/DefaultLayout.vue'

Vue.use(Router)

const routerInstance = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/',
      name: 'defaultLayout',
      redirect: { name: 'databoard' },
      component: DefaultLayout,
      children: [
        {
          path: '/databoard',
          name: 'databoard',
          component: () => import('./views/databoard/DataBoard.vue'),
        },
        {
          path: '/query',
          name: 'query',
          component: Query,
        },
        {
          path: '/products',
          name: 'products',
          component: () => import('./views/products/ProductSummary.vue'),
          children: [
            {
              path: 'SummaryBySku',
              name: 'SummaryBySku',
              component: () => import('./views/products/SummaryBySku.vue'),
            },
            {
              path: 'SummaryByBrand',
              name: 'SummaryByBrand',
              component: () => import('./views/products/SummaryByBrand.vue'),
            },
            {
              path: 'ProductNoSummary',
              name: 'ProductNoSummary',
              component: () => import('./views/products/ProductNoSummary.vue'),
            },
            {
              path: 'ProductNew',
              name: 'ProductNew',
              component: () => import('./views/products/ProductNew.vue'),
            },
          ],
        },
        {
          path: '/products/detail',
          name: 'products-detail',
          component: () => import('./views/products/Products.vue'),
        },
        {
          path: '/taskQueue',
          name: 'taskQueue',
          component: () => import('./views/taskQueue/TaskQueue.vue'),
        },
        {
          path: '/orders',
          name: 'orders',
          component: () => import('./views/orders/Orders.vue'),
        },
        {
          path: '/pack',
          name: 'pack',
          component: () => import('./views/orders/Orders.vue'),
        },
        {
          path: '/fulfilled',
          name: 'fulfilled',
          component: () => import('./views/orders/Orders.vue'),
        },
        {
          path: '/completed',
          name: 'completed',
          component: () => import('./views/orders/Orders.vue'),
        },
        {
          path: '/bills',
          name: 'bills',
          component: () => import('./views/bills/Bills.vue'),
        },
        {
          path: '/admin',
          redirect: { name: 'user' },
        },
        {
          path: '/admin/users',
          name: 'users',
          component: () => import('./views/users/Users.vue'),
        },
        {
          path: '/admin/withdraw',
          name: 'withdraw',
          component: () => import('./views/withdraw/Withdraw.vue'),
        },
        {
          path: '/admin/settings',
          name: 'settings',
          component: () => import('./views/settings/Settings.vue'),
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('./views/profile/Profile.vue'),
        },
      ],
    },
  ],
})

routerInstance.beforeEach((to, from, next) => {
  if (to.name === 'login' || to.name === 'register' || to.name === 'audit') {
    next()
    return
  }
  const token = cookies.get('token')
  if (!token) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default routerInstance
