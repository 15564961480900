<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'HStack',
}
</script>

<style lang='less' scoped>
.container {
  display: flex;
  align-items: center;
}
</style>