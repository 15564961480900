<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import fetch from 'fetch'

export default {
  name: 'app',
  async created() {
    await fetch({ url: '/api/initCsrfToken', method: 'POST' })
  },
}
</script>

<style lang="less">
@import './assets/css/oocss.less';
@import './assets/css/reset.less';
html {
  #reset();
  #oocss();
}
#app {
  height: 100%;
  color: #303133;
}
</style>
