import fetch from 'fetch'

const loginURL = '/api/login/'

export function loginPost(data) {
  return fetch({
    url: loginURL,
    method: 'POST',
    data,
    autoShowErrorMessage: true,
  })
}
