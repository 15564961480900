<template>
  <div class="container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VStack',
}
</script>

<style lang='less' scoped>
.container {
  display: flex;
  flex-direction: column;
}
</style>