<template>
  <div class="dib mr16 mb8 oh vat">
    <div class="prepend fl">
      {{ $attrs.title }}
    </div>
    <div class="dib fl">
      <el-select v-bind="$attrs" size="small" v-on="$listeners" clearable>
        <el-option
          v-for="(option, index) in options"
          :key="option.value + index"
          :label="option.label"
          :value="option.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'search-select',
  props: {
    options: {
      type: Array,
      require: true,
      default() {
        return []
      },
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .prepend {
  background-color: #f5f7fa;
  color: #909399;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 4px 0 0 4px;
  padding: 0 20px;
  width: 1px;
  white-space: nowrap;
  font-size: 13px;
  line-height: 30px;
  width: auto;
  left: 1px;
}
/deep/ .el-input__inner {
  border-radius: 0 4px 4px 0;
}
</style>
