import XLSX from 'xlsx'

// 保留同名数据的深度克隆
export const deepClone = (Target, Origin) => {
  let src, copy, prop
  if (Origin !== null) {
    for (prop in Origin) {
      src = Target[prop]
      copy = Origin[prop]
      if (copy && typeof copy === 'object') {
        if (Object.prototype.toString.call(copy) === '[object Array]') {
          src = src || []
        } else {
          src = src || {}
        }
        Target[prop] = deepClone(src, copy)
      } else {
        Target[prop] = copy
      }
    }
    return Target
  }
}

export const getTextareaLine = (str) => {
  let list = []
  if (str) {
    str = str.replace(/[ ]/g, '\n')
    list = str.split(/[(\r\n)\r\n]+/).filter((s) => s.trim().length > 0)
  }
  return list
}

export const countLine = (str) => {
  let list = getTextareaLine(str)
  return list.length
}

export const checkDomain = (str) => {
  const regDomain = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/gi
  return regDomain.test(str)
}

export const checkHost = (str) => {
  const regHost = /(?:^([\w_-]|\.)*[\w_-]$|^\*$|^@$)/
  return regHost.test(str)
}

/**
 *
 * @comment 数组数据转导出为 Excel 文件
 * @param {Array} worksheetData 二维数组
 * @param {String} fileName 文件名
 */
export const downloadXLSX = (worksheetData, fileName, suffix = '.xlsx') => {
  const workbook = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(worksheetData)
  XLSX.utils.book_append_sheet(workbook, ws, fileName)
  XLSX.writeFile(workbook, fileName + suffix)
}

/**
 *
 * @comment 判断目标字符串是否符合ipv4格式
 * @param {String} targetStr
 */
export const isIPV4 = (targetStr) => {
  const ipv4Format1 = /^((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]\d)|[1-9])(\.((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]\d)|\d)){3}$/
  const ipv4Format2 = /^((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]\d)|\d)(\.((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]\d)|\d)){2}$/
  const ipv4Format3 = /^(?=(\b|\D))(((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))\.){3}((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))(?=(\b|\D))-(?=(\b|\D))(((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))\.){3}((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))(?=(\b|\D))$/
  if (!targetStr.match(ipv4Format1) && !targetStr.match(ipv4Format2) && !targetStr.match(ipv4Format3)) {
    return false
  }
  return true
}

/**
 *
 * @comment 判断目标字符串是否符合ipv6格式
 * @param {String} targetStr
 */
export const isIPV6 = (targetStr) => {
  const ipv6Format = /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/
  return Boolean(targetStr.match(ipv6Format))
}

/**
 *
 * @comment 处理 API 返回的流数据，作为文件下载
 * @param {*} data  stream data
 * @param {Object} headers response header
 */
export const downloadFile = (data, headers, filename) => {
  if (!data) {
    throw Error('data is required in downloadFile function')
  }
  if (!headers) {
    throw Error('headers is required in downloadFile function')
  }
  if (!filename) {
    throw Error('filename is required in downloadFile function')
  }
  const contentType = headers['content-type']
  const blob = new Blob([data], { type: contentType })
  const objectUrl = URL.createObjectURL(blob)
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = objectUrl
  a.download = decodeURI(filename)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(objectUrl)
}

/**
 *
 * @comment 用于将如 < " ' 等html转码后的字符串，转换为原本的字符
 * @param {String} parsedString
 * @return {String}
 */
export function htmlDecode(parsedString) {
  return parsedString
    .replaceAll('&amp;', '&')
    .replaceAll('&gt;', '>')
    .replaceAll('&lt;', '<')
    .replaceAll('&quot;', '"')
    .replaceAll('&#39;', "'")
    .replaceAll('&nbsp;', ' ')
    .replaceAll('&#x27;', "'")
    .replaceAll('&#x60;', '`')
    .replaceAll('&copy;', '©')
}

/**
 *
 * @comment 通过 blob 数据获取图片 URL
 * @param {Blob} blob  blob data
 * @return {String} url
 */
export const getUrlFromBlob = (blob) => {
  if (!(blob instanceof Blob)) {
    throw Error('type error, suppose blob data')
  }
  const urlCreator = window.URL || window.webkitURL
  return urlCreator.createObjectURL(blob)
}
